<template>
  <div class="header">
    <div class="logo"><img src="../assets/logo-header.png" alt="" /></div>
    <div class="menu">
      <div class="sidebar cantselect">
        <el-menu
          class="sidebar-el-menu"
          :default-active="onRoutes"
          text-color="#303133"
          active-text-color="#1175ed"
          unique-opened
          router
          mode="horizontal"
        >
          <template v-for="item in items">
            <template v-if="item.subs">
              <el-submenu :index="item.sysmenu" :key="item.sysmenu">
                <template slot="title">
                  <i :class="item.menuicon"></i>
                  <span slot="title">{{ item.menutext }}</span>
                </template>
                <template v-for="subItem in item.subs">
                  <el-submenu
                    v-if="subItem.subs"
                    :index="subItem.sysmenu"
                    :key="subItem.sysmenu"
                  >
                    <template slot="title">{{ subItem.menutext }}</template>
                    <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :route="threeItem.router"
                      :index="threeItem.sysmenu"
                      >{{ threeItem.menutext }}</el-menu-item
                    >
                  </el-submenu>
                  <el-menu-item
                    v-else
                    :index="subItem.sysmenu"
                    :route="subItem.router"
                    :key="subItem.sysmenu"
                    >{{ subItem.menutext }}</el-menu-item
                  >
                </template>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                :index="item.sysmenu"
                :route="item.router"
                :key="item.sysmenu"
              >
                <i :class="item.menuicon"></i>
                <span slot="title">{{ item.menutext }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      items: [
        {
          sysmenu: "home",
          menutext: "首页",
          router: "/home",
        },
        {
          sysmenu: "200",
          menutext: "产品",
          subs: [
                       {
              sysmenu: "erp",
              menutext: "ERP",
              router: "/product/erp",
            },
            {
              sysmenu: "retail",
              menutext: "收银系统",
              router: "/product/retail",
            },
            {
              sysmenu: "cater",
              menutext: "点餐系统",
              router: "/product/cater",
            },
            {
              sysmenu: "wx",
              menutext: "小程序",
              router: "/product/wx",
            },
          ],
        },
        {
          sysmenu: "300",
          menutext: "方案",
          subs: [
            {
              sysmenu: "supermarket",
              menutext: "便利商超方案",
              router: "/plan/supermarket",
            },
          ],
        },
        {
          sysmenu: "download",
          menutext: "下载",
          router: "/download",
        },
        {
          sysmenu: "about_us",
          menutext: "关于我们",
          router: "/about_us",
        },
        // {
        //   sysmenu: "new_index",
        //   menutext: "资讯",
        //   router: "/new_index",
        // },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },

  methods: {},
};
</script>

<style scoped>
.el-menu.el-menu--horizontal {
  border-bottom: 0px;
  font-size: 50px;
}
.header {
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: center;
}
.logo {
  width: 300px;
  margin: 18px 80px;
}
.logo img {
  width: 230px;
  height: 70px;
}
.menu {
  width: 700px;
  height: 100px;
  line-height: 100px;
}
.sidebar {
  margin-top: 20px;
}
</style>