<template>
  <div class="footer">
    <div class="dutable">
      <el-row>
        <el-col :span="4"
          ><div class="grid-content bg-purple">
            <img class="logo-footer" src="../assets/logo-footer.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple-light">
            <div class="dutitle">关于我们</div>
            <div class="ducontant" @click="toAboutUs">公司介绍</div>
            <div class="ducontant" @click="toDownload">软件下载</div>
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content bg-purple-light">
            <div class="dutitle">彼思产品</div>
            <div class="ducontant" @click="toRetail">收银系统</div>
            <div class="ducontant">收银机</div>
            <div class="ducontant">小程序</div>
            <div class="ducontant">增值服务</div>
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content bg-purple-light">
            <div class="dutitle">服务支持</div>
            <div class="ducontant">常见问题</div>
            <div class="ducontant">产品教程</div>
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content bg-purple-light">
            <div class="dutitle">解决方案</div>
            <div class="ducontant">门店解决方案</div>
          </div></el-col
        >
        <el-col :span="3"
          ><div class="grid-content bg-purple-light">
            <img class="wx" src="../assets/picture/wx.png" alt="" /></div
        ></el-col>
        <el-col :span="3"
          ><div class="grid-content bg-purple-light">
            <div class="dutitle">联系我们</div>
            <div class="ducontant">电话：18122828713</div>
            <div class="ducontant">地址：惠州市惠城区翠竹二路中骏雍景湾9栋2单元504</div>
          </div></el-col
        >
      </el-row>
    </div>

    <a class="bats" href="http://beian.miit.gov.cn">粤ICP备2021027625号</a>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    // 跳转到联系我们
    toAboutUs() {
      this.$router.push({ path: "/about_us" });
    },
    // 跳转到软件下载
    toDownload() {
      this.$router.push({ path: "/download" });
    },
    // 跳转到零售系统
    toRetail() {
      this.$router.push({ path: "/product/retail" });
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 345px;
  background-image: url(http://www.zhonglunnet.com/templets/zlnet/images/footerbg.jpg);
}

.dutable {
  height: 280px;
  width: 1100px;
  margin: 0px auto;
}
.el-col {
  margin-top: 50px;
}

.dutitle {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
}
.ducontant {
  font-size: 12px;
  color: #b5b5b9;
  height: 24px;
  line-height: 24px;
}

.bats {
  font-size: 13px;
  height: 65px;
  line-height: 60px;
  color: #ffffff;
}

/* 微信二维码 */
.wx {
  width: 90px;
  height: 90px;
}
.logo-footer{
  width: 150px;
  height: 150px;
}
</style>