<template>
  <div>
    <Header></Header>
    <!-- 大图 -->
    <div class="dt">
      <div class="dt_text">
        <div class="dt_left">
          <div class="fs40 pb38 pt126">彼思软件下载区</div>
          <p class="fs16 lh33 pb38">
            系统支持：<br />
            Windows 7及以上<br />
          </p>
          <div>
            <a href="http://14.116.154.181:8891/login">
              <el-button size="medium" type="primary" plain>
                在线预览
              </el-button>
            </a>

            <a href="http://14.116.154.181:8890/exe/retail.msi">
              <el-button size="medium" type="primary" plain>
                Windows版下载</el-button
              >
            </a>

            <el-button size="medium" type="primary" plain>微信小程序</el-button>
          </div>
        </div>
        <div class="dt_right pt126">
          <img
            src="http://14.116.154.181:8890/bisi_website/gw/comput.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="jg">
      <el-table :data="tableData" border size="medium" style="width: 100%">
        <el-table-column align="center" prop="name" label=" " width="120"> </el-table-column>
        <el-table-column align="center" prop="province" label="标准款（本地部署）" width="250">
        </el-table-column>
        <el-table-column align="center" prop="city" label="标准款（云上部署）" width="250">
        </el-table-column>
        <el-table-column align="center" prop="address" label="定制款" width="">
        </el-table-column>
      </el-table>
    </div>

    <!-- 下载区 -->
    <div class="xz">
      <div class="line-title pb30">彼思生产ERP系统下载体验</div>
      <div class="xz_text">
        <div class="pl50 pr50">
          <div class="xz_icon">
            <div class="xiazaiarrowicon"></div>
            <img
              src="http://www.zhonglunnet.com/templets/zlnet/images/xiazai/android.png"
              alt=""
            />
          </div>
          <p>支持4.4.1及以上的系统</p>
          <p>版本号 3.6.2.3</p>
          <el-button size="big" type="primary">安卓版下载</el-button>
        </div>
        <div class="pl50 pr50">
          <div class="xz_icon">
            <div class="xiazaiarrowicon"></div>
            <img
              src="http://www.zhonglunnet.com/templets/zlnet/images/xiazai/window.png"
              alt=""
            />
          </div>
          <p>支持Windows 7及以上系统</p>
          <p>版本号 3.6.2.3</p>
          <a href="http://14.116.154.181:8890/exe/retail.msi">
            <el-button size="big" type="primary">Windows版下载</el-button>
          </a>
        </div>
        <div class="pl50 pr50">
          <div class="xz_icon">
            <div class="xiazaiarrowicon"></div>
            <img
              src="http://www.zhonglunnet.com/templets/zlnet/images/xiazai/phone.png"
              alt=""
            />
          </div>
          <p>支持安卓和ios系统</p>
          <p>版本号 1.0.4</p>
          <el-button size="big" type="primary">混合版下载</el-button>
        </div>
      </div>
    </div>

    <div class="xz">
      <div class="line-title pb30">彼思零售软件系统下载体验</div>
      <div class="xz_text">
        <div class="pl50 pr50">
          <div class="xz_icon">
            <div class="xiazaiarrowicon"></div>
            <img
              src="http://www.zhonglunnet.com/templets/zlnet/images/xiazai/android.png"
              alt=""
            />
          </div>
          <p>支持4.4.1及以上的系统</p>
          <p>版本号 3.6.2.3</p>
          <el-button size="big" type="primary">安卓版下载</el-button>
        </div>
        <div class="pl50 pr50">
          <div class="xz_icon">
            <div class="xiazaiarrowicon"></div>
            <img
              src="http://www.zhonglunnet.com/templets/zlnet/images/xiazai/window.png"
              alt=""
            />
          </div>
          <p>支持Windows 7及以上系统</p>
          <p>版本号 3.6.2.3</p>
          <a href="http://14.116.154.181:8890/exe/retail.msi">
            <el-button size="big" type="primary">Windows版下载</el-button>
          </a>
        </div>
        <div class="pl50 pr50">
          <div class="xz_icon">
            <div class="xiazaiarrowicon"></div>
            <img
              src="http://www.zhonglunnet.com/templets/zlnet/images/xiazai/phone.png"
              alt=""
            />
          </div>
          <p>支持安卓和ios系统</p>
          <p>版本号 1.0.4</p>
          <el-button size="big" type="primary">混合版下载</el-button>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "download",
  data() {
    return {
      tableData: [
        {
          name: "用户数",
          province: "不限",
          city: "限制",
          address: "",
        },
        {
          name: "数据备份",
          province: "",
          city: "可以恢复7天内的数据",
          address: "",
        },
        {
          name: "功能",
          province: "提供ERP的全部功能，库存、采购、销售、生产、财务、人事等",
          city: "提供ERP的全部功能，库存、采购、销售、生产、财务、人事等",
          address: "根据客户的需求进行二次开发",
        },
        {
          name: "体验开通",
          province: "电话联系:18122828713,或者添加微信：18122828713",
          city: "电话联系:18122828713,或者添加微信：18122828713",
          address: "电话联系:18122828713,或者添加微信：18122828713",
        },
      ],
    };
  },
  components: { Header, Footer },
};
</script>

<style scoped>
/* 大图 */
.dt {
  height: 538px;
  background-size: auto 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url("http://www.zhonglunnet.com/templets/zlnet/images/xiazai/banner.png");
}
.dt_text {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.dt_left {
  width: 550px;
  color: #ffffff;
  text-align: left;
}

/* 下载区 */
.xz {
  height: 500px;
}
.xz_text {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.xz_icon {
  height: 150px;
}
.xz_icon img {
  width: 88px;
  height: 106px;
}

.xiazaiarrowicon {
  width: 0;
  height: 0;
  border: 14px solid;
  border-color: transparent transparent #4e4e4e;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
a {
  margin-right: 15px;
}

.jg {
  width: 1100px;
  margin: 60px auto;
  background-color: blue;
}
.line-title {
  font-size: 30px;
  font-weight: 700;
}
</style>